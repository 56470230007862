import { useState, useEffect } from 'react';
import { ConfigVariables } from '../config';

const useUserAccount = (address: string | undefined, readOnly: boolean, refetch: boolean) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    if(readOnly || !address) {
        console.log("Read Only or no address");
        setIsLoading(false);
        return
    }
    setIsLoading(true);
    console.log("Fetching user data from:", `${ConfigVariables.SERVER_URL}/users/${address}`);
    
    fetch(`${ConfigVariables.SERVER_URL}/users/${address}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
      })
      .then(data => {
        console.log("User data received:", data);
        setIsLoading(false);
        setUser(data);
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        setIsLoading(false);
        setUser(null);
        
        // If user not found (404), we could create a new user
        if (error.message.includes('404')) {
          console.log("User not found, could create new user here");
          // Uncomment to automatically create new users:
          /*
          createAccount({
            address,
            name: '',
            email: ''
          }).then(newUser => {
            setUser(newUser);
          });
          */
        }
      });
  }, [address, readOnly, refetch]);

  return { user, isLoading };
}

export default useUserAccount;