import { useEffect, useState } from "react";
import { FormatUserSummaryResponse, FormatReserveUSDResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { ConfigVariables } from "../config";

const useAllPositions = (currentAccount: string | undefined, refreshKey?: number) => {
    const [aaveUserSummary, setAaveUserSummary] = useState<FormatUserSummaryResponse<ReserveDataHumanized & { priceInMarketReferenceCurrency: string } & FormatReserveUSDResponse> | undefined>();
    const [uniswapPositions, setUniswapPositions] = useState<UniswapPositionData[]>();
    const [loadingAave, setLoadingAave] = useState(false);
    const [loadingUniswap, setLoadingUniswap] = useState(false);
  
    useEffect(() => {
        const fetchAaveSummary = async () => {
            if (!currentAccount || !ConfigVariables.FEATURES.AAVE_ENABLED) {
                setAaveUserSummary(undefined);
                setLoadingAave(false);
                return;
            }
        
            const queryParams = new URLSearchParams({
                currentAccount: currentAccount,
            });
            
            try {
                setLoadingAave(true);
                const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/aave-user-summary?${queryParams}`, {
                    method: 'GET', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                const userSummary = data?.aaveUserSummary;
                setAaveUserSummary(userSummary);
            } catch (error) {
                console.error('Error fetching Aave summary:', error);
                setAaveUserSummary(undefined);
            } finally {
                setLoadingAave(false);
            }
        }

        const fetchUniswapSummary = async () => {
            if(!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            try {
                setLoadingUniswap(true);
                const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/uniswap-user-summary?${queryParams}`, {
                    method: 'GET', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const positions = data?.uniswapUserSummary;
                setUniswapPositions(positions);
            } catch (error) {
                console.error('Error fetching Uniswap summary:', error);
                setUniswapPositions(undefined);
            } finally {
                setLoadingUniswap(false);
            }
        }
      
        fetchAaveSummary();
        fetchUniswapSummary();
    }, [currentAccount, refreshKey]);
  
    return { aaveUserSummary, loadingAave, uniswapPositions, loadingUniswap };
};
  
export default useAllPositions;